import {EditGuesser, FieldGuesser, ShowGuesser} from "@api-platform/admin";
import {
    Datagrid,
    DateInput,
    TextInput,
    List,
    ReferenceInput,
    AutocompleteInput,
    SearchInput,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton, ExportButton, ReferenceField, TextField
} from "react-admin";
import React from "react";

const DemandeAdhesionListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);
const filters = [
    <SearchInput source="q" alwaysOn/>,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <TextInput source={"email"} />,
    <TextInput source={"nom"} />,
    <TextInput source={"prenom"} />,
];

export const DemandeAdhesionList = props => (
    <List{...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                <TextField source="titre"/>
            </ReferenceField>
            <FieldGuesser source={"email"}/>
            <FieldGuesser source={"telephone"}/>
            <FieldGuesser source={"nom"}/>
            <FieldGuesser source={"prenom"}/>
            <FieldGuesser source={"dateNaissance"}/>
        </Datagrid>
    </List>
);
export const DemandeAdhesionShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"communaute"} />
        <FieldGuesser source={"email"}/>
        <FieldGuesser source={"telephone"}/>
        <FieldGuesser source={"nom"}/>
        <FieldGuesser source={"prenom"}/>
        <FieldGuesser source={"dateNaissance"}/>
    </ShowGuesser>
);
export const DemandeAdhesionEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"} disabled />
        </ReferenceInput>
        <TextInput source={"email"} type={"email"}/>
        <TextInput source={"telephone"}/>
        <TextInput source={"nom"}/>
        <TextInput source={"prenom"}/>
        <DateInput source={"dateNaissance"}/>
    </EditGuesser>
);